var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"mask-closable":false,"width":"1200px"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v(_vm._s(_vm.item.code)+"----参管项目信息")]),_c('div',{staticClass:"user-edit-content"},[_c('div',{staticClass:"query-data-area"},[_vm._v(" 项目名称： "),_c('Input',{staticStyle:{"width":"200px","margin-right":"10px"},attrs:{"placeholder":"请输入项目名称","clearable":""},model:{value:(_vm.filter.appName),callback:function ($$v) {_vm.$set(_vm.filter, "appName", $$v)},expression:"filter.appName"}}),_vm._v(" 组织名称： "),_c('Input',{staticStyle:{"width":"200px","margin-right":"10px"},attrs:{"placeholder":"请输入组织名称","clearable":""},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}}),_c('Button',{attrs:{"type":"primary"},on:{"click":_vm.search}},[_vm._v("查询")])],1),_c('div',{ref:"table1",staticClass:"alarm-data-area"},[_c('vxe-table',{attrs:{"resizable":"","border":"","show-overflow":"","row-key":"","row-config":{isHover: true},"data":_vm.list,"scroll-y":{enabled: false},"height":"500"}},[_c('vxe-column',{attrs:{"field":"isOwner","title":"拥有者","width":"70","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{style:({color: row.isOwner ? 'blue' : 'green'})},[_vm._v(_vm._s(row.isOwner ? '是' : '否'))])]}}])}),_c('vxe-column',{attrs:{"field":"enable","title":"状态","width":"60","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{style:({color: row.enable ? 'green' : 'red'})},[_vm._v(_vm._s(row.enable ? '启用' : '未启用'))])]}}])}),_c('vxe-column',{attrs:{"field":"appName","title":"项目名称","header-align":"center","width":"200"}}),_c('vxe-column',{attrs:{"field":"name","title":"所属组织","width":"200","header-align":"center"}}),_c('vxe-column',{attrs:{"field":"area","title":"所在地区","width":"100","header-align":"center"}}),_c('vxe-column',{attrs:{"field":"roleName","title":"角色权限","header-align":"center","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.roleNames.join(','))+" ")]}}])}),_c('vxe-column',{attrs:{"field":"groupName","title":"分区权限","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.groupNames.join(','))+" ")]}}])}),_c('vxe-column',{attrs:{"width":"180"},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.funCodes('sue'))?_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"size":"small","type":"primary"},on:{"click":_vm.handleAdd}},[_vm._v("加入项目")]):_vm._e()]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.isOwner && _vm.funCodes('sue'))?_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"size":"small","type":"error"},on:{"click":function($event){return _vm.handleRemove(row)}}},[_vm._v("退出项目")]):_vm._e(),(_vm.funCodes('sue'))?_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.setPermissions(row)}}},[_vm._v("设置权限")]):_vm._e()]}}])})],1)],1),_c('vxe-pager',{attrs:{"layouts":_vm.pageLayouts,"current-page":_vm.filter.index,"page-size":_vm.filter.size,"total":_vm.total,"page-sizes":_vm.pageSizes},on:{"update:currentPage":function($event){return _vm.$set(_vm.filter, "index", $event)},"update:current-page":function($event){return _vm.$set(_vm.filter, "index", $event)},"update:pageSize":function($event){return _vm.$set(_vm.filter, "size", $event)},"update:page-size":function($event){return _vm.$set(_vm.filter, "size", $event)},"page-change":_vm.handlePageChange}}),_c('ModalAddCust',{attrs:{"item":_vm.editItem},on:{"saved":_vm.custAdded},model:{value:(_vm.showCustModal),callback:function ($$v) {_vm.showCustModal=$$v},expression:"showCustModal"}}),_c('ModalPowerEdit',{attrs:{"item":_vm.editItem},on:{"saved":_vm.search},model:{value:(_vm.showPowerModal),callback:function ($$v) {_vm.showPowerModal=$$v},expression:"showPowerModal"}})],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{staticStyle:{"margin-right":"8px"},on:{"click":_vm.cancel}},[_vm._v("关闭")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }