var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"mask-closable":false,"width":"1320px"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v("组织 【"+_vm._s(_vm.item.name)+"】 应用 【"+_vm._s(_vm.item.appName)+"】 的用户信息")]),_c('div',{staticClass:"edit-area"},[_c('vxe-table',{ref:"chkTable",attrs:{"border":"","resizable":"","show-overflow":"","show-header-overflow":"","data":_vm.list,"height":600,"row-config":{isHover: true}}},[_c('vxe-column',{attrs:{"type":"seq","width":"60"}}),_c('vxe-column',{attrs:{"field":"isOwner","title":"拥有者","width":"70","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{style:({color: row.isOwner ? 'blue' : 'green'})},[_vm._v(_vm._s(row.isOwner ? '是' : '否'))])]}}])}),_c('vxe-column',{attrs:{"field":"stats","title":"状态","width":"70","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getUserState(row))+" ")]}}])}),_c('vxe-column',{attrs:{"field":"code","title":"登录名","width":"120","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.code)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"name","title":"姓名","width":"100","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"mobile","title":"手机号","width":"130","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.mobile)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"needExpire","title":"过期时间","width":"100","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.needExpire ? new Date(row.expire).format('yyyy-MM-dd') : '永久')+" ")]}}])}),_c('vxe-column',{attrs:{"field":"roleName","title":"角色权限","header-align":"center","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.roleNames.join(','))+" ")]}}])}),_c('vxe-column',{attrs:{"field":"groupName","title":"分区权限","header-align":"center","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.groupNames.join(','))+" ")]}}])}),_c('vxe-column',{attrs:{"width":"275","title":"操作","fixed":"right"},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.funCodes('9907'))?_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"size":"small","type":"primary"},on:{"click":_vm.handleAdd}},[_vm._v("新增成员")]):_vm._e(),_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"size":"small","type":"primary"},on:{"click":_vm.getList}},[_vm._v("刷新")])]},proxy:true},{key:"default",fn:function(params){return [(!params.row.isOwner && _vm.funCodes('sce1'))?_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"size":"small","type":"success"},on:{"click":function($event){return _vm.handleOwner(params)}}},[_vm._v("设置拥有者")]):_vm._e(),(_vm.funCodes('9908'))?_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.handleEdit(params)}}},[_vm._v("设置权限")]):_vm._e(),(!params.row.isOwner && _vm.funCodes('9909'))?_c('Button',{attrs:{"size":"small","type":"error"},on:{"click":function($event){return _vm.handleDelete(params)}}},[_vm._v("移出项目")]):_vm._e()]}}])})],1)],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{staticStyle:{"margin-right":"8px"},on:{"click":_vm.cancel}},[_vm._v("关闭")])],1),_c('ModalUserEdit',{attrs:{"item":_vm.editItem},on:{"saved":_vm.itemSaved},model:{value:(_vm.showEditModal),callback:function ($$v) {_vm.showEditModal=$$v},expression:"showEditModal"}}),_c('ModalUserSelect',{attrs:{"item":_vm.item},on:{"saved":_vm.itemSaved},model:{value:(_vm.showUserModal),callback:function ($$v) {_vm.showUserModal=$$v},expression:"showUserModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }