import { render, staticRenderFns } from "./ModalRoleList.vue?vue&type=template&id=bb1eae6a&scoped=true&"
import script from "./ModalRoleList.vue?vue&type=script&lang=js&"
export * from "./ModalRoleList.vue?vue&type=script&lang=js&"
import style0 from "./ModalRoleList.vue?vue&type=style&index=0&id=bb1eae6a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb1eae6a",
  null
  
)

export default component.exports